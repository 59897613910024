
  import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component
  export default class InfoDialog extends Vue {
  // Propiedad recibida desde el componente padre
  @Prop({ type: Boolean, default: false }) readonly value!: boolean;

  // Computed para manejar el v-model localmente
  get localValue (): boolean {
    return this.value
  }

  set localValue (newValue: boolean) {
    this.emitValue(newValue)
  }

  // Método para emitir el cambio de valor al padre
  @Emit('input')
  emitValue (value: boolean): boolean {
    return value
  }

  // Método para cerrar el diálogo
  closeDialog (): void {
    this.localValue = false
  }
  }
